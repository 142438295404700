import { request } from "../request";
import { message } from "antd";

export const getAllUsers = async (params?: {
  page?: number;
  pageSize?: number;
  filters?: {
    TANI: string;
  }[];
}) => {
  const { page = 1, pageSize = 10, filters } = params ?? {};
  const response = await request.post(`report/person/all`, {
    page,
    pageSize,
    filters,
  });
  if (response?.code) {
    message.error("Kullanıcı verileri alınamadı");
    return [];
  }
  return response;
};

export const getUserById = async (id: string) => {
  const response = await request.get(`users/id/${id}`);
  return response;
};

export const getPersonWithAllDetails = async (id: number) => {
  const response = await request.get(`report/person/${id}`);
  return response;
};

export const getTanilar = async () => {
  const response = await request.get(`report/tani/tanilar`);
  return response;
};

export const getTotalCount = async () => {
  const response = await request.get(`user/totalPersonCount`);
  return response;
};

export const getCronStatus = async () => {
  const response = await request.get(`status`);
  return response;
};
