import React from "react";
import { useParams } from "react-router";
import { useDetail } from "./detail.hook";
import { Card, Col, Row, Spin } from "antd";

const PersonDetails = () => {
  const { id } = useParams();
  const { person, loading } = useDetail({ id: Number(id) });

  const renderRow = (label: string, value?: string) => {
    return (
      <Col
        span={24}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <span
          style={{
            fontWeight: 500,
          }}
        >
          {label}:
        </span>{" "}
        <span
          style={{
            marginLeft: 4,
          }}
        >
          {label === "GSM"
            ? value
                ?.split(",")
                .map((item) => (
                  <div>
                    {item.replace(
                      /(\d{3})(\d{3})(\d{2})(\d{2})/,
                      "($1) $2 $3 $4",
                    )}
                  </div>
                ))
            : value}
        </span>
      </Col>
    );
  };
  return (
    <div>
      {loading ? (
        <Spin spinning={loading} size="large" />
      ) : (
        <>
          <Card>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <h3>
                  {person?.ADI} {person?.SOYADI}
                </h3>
              </Col>
              <Col span={12}>
                <Row gutter={[8, 8]}>
                  {renderRow("TC", person?.TC)}
                  {renderRow("Doğum Tarihi", person?.DOGUMTARIHI)}
                  {renderRow("Cinsiyet", person?.CINSIYET)}
                  {renderRow("İlçe", person?.NUFUSILCE)}
                  {renderRow("Anne TC", person?.ANNETC)}
                  {renderRow("Baba TC", person?.BABATC)}
                  {renderRow("GSM", person?.GSM)}
                  {renderRow("Uyruk", person?.UYRUK)}
                </Row>
              </Col>
              <Col span={12}>
                <h4>Raporlar</h4>
                {/* I should show the reports with their BASLANGICTARIH and BITISTARIH values, and also TANI and RAPORNO and TAKIPNO */}
                {person?.report.map((report) => {
                  return (
                    <Card title={report.RAPORNO}>
                      <Row gutter={[16, 8]}>
                        <Col span={12}>
                          <span>Başlangıç Tarihi: {report.BASLANGICTARIH}</span>
                        </Col>
                        <Col span={12}>
                          <span>Bitiş Tarihi: {report.BITISTARIH}</span>
                        </Col>

                        <Col span={24}>
                          <span>Takip No: {report.TAKIPNO}</span>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col span={24}>
                          <span>
                            Tanı:{" "}
                            <span
                              style={{ fontWeight: 600, color: "forestgreen" }}
                            >
                              {report.TANI}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              </Col>
              <Col span={24}>
                <h3
                  style={{
                    textAlign: "center",
                  }}
                >
                  Reçeteler
                </h3>
                <Row gutter={[8, 8]}>
                  {person?.prescriptions.map((prescription) => {
                    return (
                      <Col span={24}>
                        <Card title={prescription.RECETENO}>
                          {prescription.drugs.map((drug) => {
                            return (
                              <Row>
                                <Col span={12}>
                                  <span>{drug.drug.ADI}</span>
                                </Col>
                                <Col span={12}>
                                  <span>{drug.usage}</span>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </div>
  );
};

export default PersonDetails;
