import React from "react";
import { Button, Col, message, Row, Select, Table, Tag } from "antd";
import { useNavigate } from "react-router";
import { useUsers } from "./users.hook";

const Users = () => {
  const {
    users,
    tanilar,
    setSelectedTani,
    selectedTani,
    loading,
    pageSize,
    page,
    setPageSize,
    setPage,
    total,
  } = useUsers();

  const navigate = useNavigate();

  const options = tanilar?.map((item: any) => ({
    label: item.TANI,
    value: item.TANI,
  }));
  return (
    <div>
      <Row
        style={{
          marginBottom: 16,
        }}
        justify={"end"}
      >
        <Col span={12}>
          <Select
            style={{
              width: "100%",
            }}
            value={selectedTani}
            onChange={(value) => {
              setSelectedTani(value);
            }}
            options={options}
            placeholder="Tanılar"
            showSearch
          />
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            onClick={() => {
              setPage(1);
              setSelectedTani(null);
            }}
          >
            Filtreyi Sıfırla
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        scroll={{ x: 900 }}
        pagination={{
          pageSize,
          total,
          current: page,
        }}
        onChange={(pagination) => {
          setPage(pagination.current || 1);
          setPageSize(pagination.pageSize || 10);
        }}
        dataSource={users}
        columns={[
          {
            title: "TC",
            key: "TC",
            dataIndex: "TC",
          },
          {
            title: "Ad Soyad",
            key: "TC",
            render: (record: any) => (
              <span>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(`/users/${record.id}`);
                  }}
                >
                  {record.ADI} {record.SOYADI}
                </Button>
              </span>
            ),
          },
          {
            title: "Cinsiyet",
            key: "TC",
            dataIndex: "CINSIYET",
          },
          {
            title: "Doğum Tarihi",
            key: "TC",
            dataIndex: "DOGUMTARIHI",
          },
          {
            title: "İlçe",
            key: "TC",
            dataIndex: "NUFUSILCE",
          },
          {
            title: "Telefon",
            key: "TC",
            dataIndex: "GSM",
            render: (phone: string) => (
              <div>
                {phone.split(",").map((item: string) => (
                  <div>
                    {item.replace(
                      /(\d{3})(\d{3})(\d{2})(\d{2})/,
                      "($1) $2 $3 $4",
                    )}
                  </div>
                ))}
              </div>
            ),
          },
          {
            title: "Durum",
            dataIndex: "status",
            render: (status: string) => (
              <span>
                {status === "active" ? (
                  <Tag color="green">Aktif</Tag>
                ) : (
                  <Tag color="blue">Tamamlandı</Tag>
                )}
              </span>
            ),
          },
          {
            title: "Aksiyon",
            key: "TC",
            render: (record: any) => (
              <div>
                <Button
                  onClick={() => {
                    message.error("Düzenleme sayfası henüz yok :D");
                  }}
                  type="primary"
                >
                  Düzenle
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Users;
