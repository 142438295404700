import { getAllUsers, getTanilar } from "../../services/users/users.service";
import { useCallback, useEffect, useState } from "react";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [tanilar, setTanilar] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTani, setSelectedTani] = useState<string | null>(null); // [1]
  const [loading, setLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const response = await getAllUsers({
      page,
      pageSize,
      filters: selectedTani ? [{ TANI: selectedTani }] : undefined,
    });
    setUsers(response?.data);
    setTotal(response?.total);
    setLoading(false);
  }, [page, pageSize, selectedTani]);

  const fetchTanilar = useCallback(async () => {
    setLoading(true);
    const response = await getTanilar();
    setTanilar(response?.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page, pageSize, selectedTani, fetchUsers]);

  useEffect(() => {
    fetchTanilar();
  }, [fetchTanilar]);

  return {
    users,
    tanilar,
    total,
    selectedTani,
    setSelectedTani,
    page,
    pageSize,
    setPageSize,
    setPage,
    fetchUsers,
    loading,
  };
};
