import React, { FC, useEffect } from "react";
import { Button, Layout, Menu, MenuProps, Tag } from "antd";
import styles from "./layout.module.scss";
import {
  PieChartOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router";
import { STORAGE } from "../../storage";
import { combineClassnames } from "../../assets/utils/combineClassnames";
import {
  getTotalCount,
  getCronStatus,
} from "../../services/users/users.service";

const { Header, Footer, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Kontrol Paneli", "/dashboard", <PieChartOutlined />),
  getItem("Kullanıcılar", "/users", <UserOutlined />),
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  // getItem("Team", "sub2", <TeamOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  // getItem("Files", "9", <FileOutlined />),
];
interface PageLayoutProps {
  children: React.ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const [selectedMenuItemKey, setSelectedMenuItemKey] =
    React.useState<React.Key>("1");
  const [collapsed, setCollapsed] = React.useState(false);
  const [totalUserCount, setTotalUserCount] = React.useState(0);
  const [cronStatus, setCronStatus] = React.useState(false);

  const getTotalUserCount = async () => {
    try {
      const response = await getTotalCount();
      if (response?.data) {
        setTotalUserCount(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCronStatus = async () => {
    try {
      const response = await getCronStatus();
      if (response?.status) {
        setCronStatus(response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotalUserCount();
    fetchCronStatus();
  }, []);

  const navigate = useNavigate();
  return (
    <Layout hasSider>
      <Sider
        className={styles.sider}
        collapsible
        collapsedWidth={70}
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="md"
      >
        <div
          className={combineClassnames(
            styles.logoContainer,
            collapsed ? styles.collapsed : "",
          )}
        >
          <img src={Logo} className={styles.logo} alt="logo" />
        </div>
        <div>
          <h5 className={styles.logoText}>People Portal</h5>
        </div>
        <div className={styles.totalUserCount}>
          Toplam Kullanıcı: {totalUserCount}
        </div>
        <div>
          <div className={styles.cronStatus}>
            Cron Durumu:{" "}
            <span
              className={combineClassnames(
                styles.status,
                cronStatus ? styles.success : styles.error,
              )}
            >
              {cronStatus ? (
                <Tag color="green">Çalışıyor</Tag>
              ) : (
                <Tag color="red">Durdu</Tag>
              )}
            </span>
          </div>
        </div>
        <Menu
          theme="dark"
          className={styles.menu}
          selectedKeys={[selectedMenuItemKey as string]}
          mode="inline"
          items={items}
          onClick={({ key }) => {
            setSelectedMenuItemKey(key);
            navigate(key);
          }}
        />

        <Button
          className={styles.logoutButton}
          type="primary"
          onClick={() => {
            STORAGE.removeRefreshToken();
            STORAGE.removeAccessToken();
            navigate("/login");
          }}
        >
          <PoweroffOutlined />
        </Button>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "transparent" }} />
        <Content
          style={{
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>

        <Footer>
          <div>Breath Matters.</div>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
