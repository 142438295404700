import { useCallback, useEffect, useState } from "react";
import { getPersonWithAllDetails } from "../../services/users/users.service";

export type Drug = {
  drug: {
    id: number;
    ADI: string;
  };
  drugId: number;
  prescriptionId: number;
  usage: string;
};

export type Prescription = {
  id: number;
  personId: number;
  RECETENO: string;
  drugs: Drug[];
};

export type Report = {
  id: number;
  personId: number;
  BASLANGICTARIH: string;
  BITISTARIH: string;
  KAYITSEKLI: string;
  RAPORNO: string;
  TAKIPNO: string;
  TANI: string;
};

export type Person = {
  TC: string;
  ADI: string;
  SOYADI: string;
  CINSIYET: string;
  ANNETC: string;
  BABATC: string;
  DOGUMTARIHI: string;
  NUFUSIL: string;
  NUFUSILCE: string;
  GSM: string;
  UYRUK: string;
  id: number;
  prescriptions: Prescription[];
  report: Report[];
};

export const useDetail = ({ id }: { id: number }) => {
  const [person, setPerson] = useState<Person | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const fetchPerson = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPersonWithAllDetails(id);
      setPerson(response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchPerson();
  }, [fetchPerson]);

  return {
    person,
    loading,
    error,
  };
};
