import React from "react";

const Dashboard = () => {
  return (
    <div>
      Buraya kullanıcı kayıt istatistikleri ve chartları, cihaz talebi
      bildirimleri gibi veriler gelecek.
    </div>
  );
};

export default Dashboard;
