import { request } from "../request";
import { STORAGE } from "../../storage";

export const login = async (username: string, password: string) => {
  const response = await request.post("auth/login", { username, password });
  return response;
};

export const logout = async () => {
  await request.post("auth/logout", {});
  STORAGE.clear();
};
