import { config } from "../config";
import { message } from "antd";
import { logout } from "./auth/auth.service";

const getAccessToken = () => {
  return localStorage.getItem("@accessToken");
};

/*const getRefreshToken = () => {
  return localStorage.getItem("@refreshToken");
};*/

const handleResponse = async (response: Response) => {
  if (response.status === 401 || response.status === 403) {
    message.error("Token süresi doldu. Lütfen tekrar giriş yapınız.");
    logout();
    setTimeout(() => {
      window.location.href = "/login";
    }, 5000);
  } else {
    return response.json();
  }
};

const get = async (url: string, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on GET request");
  }
};

const post = async (url: string, body: any, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on POST request");
  }
};

const put = async (url: string, body: any, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on PUT request");
  }
};

const del = async (url: string, headers?: any) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
  const requestUrl = `${config.apiUrl}/${url}`;
  try {
    const response = await fetch(requestUrl, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.log("error", error);
    message.error("Error on DELETE request");
  }
};

export const request = {
  get,
  post,
  put,
  delete: del,
};
